import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { hardGoTo } from '@redux/actions';
import { apiLocation } from '../../../../config/env.json';
import { createFlashMessage } from '../../components/Flash/redux';
import { postCheckPassword, putUserUpdateEmail } from '../../utils/ajax';
import { updateMyEmailComplete, updateMyEmailError } from './actions';

function* updateUserEmailApiCall({ payload: { email: newEmail } }) {
  const { data: updateData } = yield call(putUserUpdateEmail, newEmail);

  const success = updateData.success;
  const flashMessage = {
    type: success ? 'success' : 'danger',
    message: updateData.message
  };
  yield put(createFlashMessage(flashMessage));

  yield put(
    updateMyEmailComplete({
      success,
      payload: { email: newEmail, isEmailVerified: false }
    })
  );

  return success;
}

function* checkOrCreateUserPassword({ payload: { password } }) {
  const { data: passwordData } = yield call(postCheckPassword, password);

  if (!passwordData.success) {
    const flashMessage = {
      type: 'danger',
      message: passwordData.message
    };
    yield put(createFlashMessage(flashMessage));

    yield put(updateMyEmailError({}));
  }

  return passwordData.success;
}

function* redirectUserToSignOut() {
  yield delay(3000);
  yield put(hardGoTo(`${apiLocation}/signout`));
}

function* updateMyEmailSaga({
  payload: { email: newEmail, password: oldPassword, isCreatingPassword }
}) {
  try {
    if (isCreatingPassword) {
      const successOnUpdatingEmail = yield call(updateUserEmailApiCall, {
        payload: { email: newEmail }
      });

      if (successOnUpdatingEmail) {
        const successOnCreatePassword = yield call(checkOrCreateUserPassword, {
          payload: { password: oldPassword }
        });

        if (successOnCreatePassword) {
          yield call(redirectUserToSignOut);
        }
      }

      return;
    }

    const successOnCheckPassword = yield call(checkOrCreateUserPassword, {
      payload: { password: oldPassword }
    });
    if (!successOnCheckPassword) {
      return;
    }

    const successOnUpdatingEmail = yield call(updateUserEmailApiCall, {
      payload: { email: newEmail }
    });

    if (successOnUpdatingEmail) {
      yield call(redirectUserToSignOut);
    }
  } catch (e) {
    const flashMessage = {
      type: 'danger',
      message: e.message
    };
    yield put(createFlashMessage(flashMessage));
    yield put(updateMyEmailError({}));
  }
}

export function createUpdateMyEmailSaga(types) {
  return [takeEvery(types.updateMyEmail, updateMyEmailSaga)];
}
