import cookies from 'browser-cookies';
import envData from '../../../config/env.json';

import type {
  ChallengeFile,
  ChallengeFiles,
  CompletedChallenge,
  CourseCodeDataProps,
  DataChangeTourStatusProps,
  ImageProps,
  MessageProps,
  SavedChallenge,
  SavedChallengeFile,
  User
} from '../redux/prop-types';
// eslint-disable-next-line import/no-cycle
import { formatCPF } from './general-functions';

const { apiLocation } = envData;

const base = apiLocation;

const defaultOptions: RequestInit = {
  credentials: 'include'
};

// csrf_token is passed to the client as a cookie. The client must send
// this back as a header.
function getCSRFToken() {
  const token =
    typeof window !== 'undefined' ? cookies.get('csrf_token') : null;
  return token ?? '';
}

export interface ResponseWithData<T> {
  response: Response;
  data: T;
}

async function getEcommerce(path: string) {
  const options: RequestInit = {
    method: 'GET'
  };

  const result = await fetch(`${apiLocation}/${path}`, options);
  const status = result.status;
  const data: unknown = await result.json();
  return {
    status,
    data
  };
}

// TODO: Might want to handle flash messages as close to the request as possible
// to make use of the Response object (message, status, etc)
async function get<T>(path: string): Promise<ResponseWithData<T>> {
  const response = await fetch(`${base}${path}`, defaultOptions);

  return combineDataWithResponse(response);
}

async function combineDataWithResponse<T>(response: Response) {
  const data = (await response.json()) as T;
  return { response, data };
}

export function post<T = void>(
  path: string,
  body: unknown
): Promise<ResponseWithData<T>> {
  return request('POST', path, body);
}

export function postAuth<T = void>(
  path: string,
  body: unknown
): Promise<ResponseWithData<T>> {
  return requestWithoutCSRF('POST', path, body);
}

export function putAuth<T = void>(
  path: string,
  body: unknown
): Promise<ResponseWithData<T>> {
  return requestWithoutCSRF('PUT', path, body);
}

function put<T = void>(
  path: string,
  body: unknown
): Promise<ResponseWithData<T>> {
  return request('PUT', path, body);
}

function deleteRequest<T = void>(
  path: string,
  body: unknown
): Promise<ResponseWithData<T>> {
  return request('DELETE', path, body);
}

async function request<T>(
  method: 'POST' | 'PUT' | 'DELETE',
  path: string,
  body: unknown
): Promise<ResponseWithData<T>> {
  const options: RequestInit = {
    ...defaultOptions,
    method,
    headers: {
      'CSRF-Token': getCSRFToken(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  const response = await fetch(`${base}${path}`, options);
  return combineDataWithResponse(response);
}

async function requestWithoutCSRF<T>(
  method: 'POST' | 'PUT' | 'DELETE',
  path: string,
  body: unknown
): Promise<ResponseWithData<T>> {
  const options: RequestInit = {
    ...defaultOptions,
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };

  const response = await fetch(`${base}${path}`, options);
  return combineDataWithResponse(response);
}

// ##############################################
// #### USER SETTINGS
// ##############################################

/** GET **/

interface SessionUser {
  user?: { [username: string]: User };
}

type CompleteChallengeFromApi = {
  files: Array<Omit<ChallengeFile, 'fileKey'> & { key: string }>;
} & Omit<CompletedChallenge, 'challengeFiles'>;

type SavedChallengeFromApi = {
  files: Array<Omit<SavedChallengeFile, 'fileKey'> & { key: string }>;
} & Omit<SavedChallenge, 'challengeFiles'>;

type ApiSessionResponse = Omit<SessionUser, 'user'>;
type ApiUser = {
  user: {
    [username: string]: Omit<
      User,
      'completedChallenges' & 'savedChallenges'
    > & {
      completedChallenges?: CompleteChallengeFromApi[];
      savedChallenges?: SavedChallengeFromApi[];
    };
  };
  result?: string;
};

type UserResponse = {
  user: { [username: string]: User } | Record<string, never>;
  result: string | undefined;
};

function parseApiResponseToClientUser(data: ApiUser): UserResponse {
  const userData = data.user?.[data?.result ?? ''];
  let completedChallenges: CompletedChallenge[] = [];
  let savedChallenges: SavedChallenge[] = [];
  if (userData) {
    completedChallenges = mapFilesToChallengeFiles(
      userData.completedChallenges
    );
    savedChallenges = mapFilesToChallengeFiles(userData.savedChallenges);
  }
  return {
    user: {
      [data.result ?? '']: { ...userData, completedChallenges, savedChallenges }
    },
    result: data.result
  };
}

// TODO: this at least needs a few aliases so it's human readable
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function mapFilesToChallengeFiles<File, Rest>(
  fileContainer: ({ files: (File & { key: string })[] } & Rest)[] = []
) {
  return fileContainer.map(({ files, ...rest }) => ({
    ...rest,
    challengeFiles: mapKeyToFileKey(files)
  }));
}

function mapKeyToFileKey<K>(
  files: (K & { key: string })[]
): (Omit<K, 'key'> & { fileKey: string })[] {
  return files.map(({ key, ...rest }) => ({ ...rest, fileKey: key }));
}

export function getSessionUser(): Promise<ResponseWithData<SessionUser>> {
  const responseWithData: Promise<
    ResponseWithData<ApiUser & ApiSessionResponse>
  > = get('/user/get-session-user');
  // TODO: Once DB is migrated, no longer need to parse `files` -> `challengeFiles` etc.
  return responseWithData.then(({ response, data }) => {
    const { result, user } = parseApiResponseToClientUser(data);
    return {
      response,
      data: {
        result,
        user
      }
    };
  });
}

type UserProfileResponse = {
  entities: Omit<UserResponse, 'result'>;
  result: string | undefined;
};
export function getUserProfile(
  username: string
): Promise<ResponseWithData<UserProfileResponse>> {
  const responseWithData = get<{ entities?: ApiUser; result?: string }>(
    `/api/users/get-public-profile?username=${username}`
  );
  return responseWithData.then(({ response, data }) => {
    const { result, user } = parseApiResponseToClientUser({
      user: data.entities?.user ?? {},
      result: data.result
    });
    return {
      response,
      data: {
        entities: { user },
        result
      }
    };
  });
}

type ResultEcommerce = {
  status: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: object;
};

type ResultSuccessEcommerce = {
  status: number;
  data: ResultSuccessData;
};

type ResultErrorEcommerce = {
  status: number;
  data: ResultErrorData;
};

type ResultSuccessData = {
  nivelAcesso?: string;
  turma?: string;
};

type ResultErrorData = {
  messages?: ErrorDataObj[];
};

type ErrorDataObj = {
  message: string;
  type: string;
};

export type ParseResultEcommerceProps = {
  type: string;
  title: string;
  message: string;
  nivelAcesso: string | undefined;
  turma: string | undefined;
};

function parseResultEcommerce(
  response: ResultEcommerce
): ParseResultEcommerceProps {
  switch (response.status) {
    case 401: {
      return {
        type: 'Error',
        title: 'Indisponivel',
        message: 'Serviço indisponivel no momento, tente novamente mais tarde.',
        nivelAcesso: undefined,
        turma: undefined
      };
    }
    case 200: {
      const responseSuccess: ResultSuccessEcommerce = response;
      return {
        type: 'Success',
        title: 'Sucesso!',
        message: 'Complemento de dados realizado com sucesso.',
        nivelAcesso: responseSuccess.data.nivelAcesso,
        turma: responseSuccess.data.turma
      };
    }
    case 400: {
      const responseError: ResultErrorEcommerce = response;
      const { message, type } = responseError.data.messages[0];
      return {
        type: 'Error',
        title: type ?? '',
        message: message ?? '',
        nivelAcesso: undefined,
        turma: undefined
      };
    }
    default: {
      return {
        type: 'Error',
        title: 'Manutenção',
        message: 'Serviço em manutenção',
        nivelAcesso: undefined,
        turma: undefined
      };
    }
  }
}

interface Cert {
  certTitle: string;
  username: string;
  date: Date;
  completionTime: string;
}
export function getShowCert(
  username: string,
  certSlug: string
): Promise<ResponseWithData<Cert>> {
  return get(`/certificate/showCert/${username}/${certSlug}`);
}

export function getUsernameExists(
  username: string
): Promise<ResponseWithData<boolean>> {
  return get(`/api/users/exists?username=${username}`);
}

/** POST **/

interface Report {
  username: string;
  reportDescription: string;
}
export function postReportUser(body: Report): Promise<ResponseWithData<void>> {
  return post('/user/report-user', body);
}

// Both are called without a payload in danger-zone-saga,
// which suggests both are sent without any body
// TODO: Convert to DELETE
export function postDeleteAccount(): Promise<ResponseWithData<void>> {
  return post('/account/delete', {});
}

export function postResetProgress(): Promise<ResponseWithData<void>> {
  return post('/account/reset-progress', {});
}

export function postUserToken(): Promise<ResponseWithData<void>> {
  return post('/user/user-token', {});
}

export function postSaveChallenge(body: {
  id: string;
  files: ChallengeFiles;
}): Promise<ResponseWithData<void>> {
  return post('/save-challenge', body);
}

// ##############################################
// #### USER PROFILE
// ##############################################

/** PUT **/

interface MyAbout {
  name: string;
  location: string;
  about: string;
  picture: string;
}
export function putUpdateMyAbout(
  values: MyAbout
): Promise<ResponseWithData<void>> {
  return put('/update-my-about', { ...values });
}

export function putUpdateMyUsername(
  username: string
): Promise<ResponseWithData<void>> {
  return put('/update-my-username', { username });
}

export function putUpdateMyProfileUI(
  profileUI: User['profileUI']
): Promise<ResponseWithData<void>> {
  return put('/update-my-profileui', { profileUI });
}

export function putUpdateMySocials(
  update: Record<string, string>
): Promise<ResponseWithData<void>> {
  return put('/update-my-socials', update);
}

export function putUpdateMySound(
  update: Record<string, string>
): Promise<ResponseWithData<void>> {
  return put('/update-my-sound', update);
}

export function putUpdateMyTheme(
  update: Record<string, string>
): Promise<ResponseWithData<void>> {
  return put('/update-my-theme', update);
}

export function putUpdateMyKeyboardShortcuts(
  update: Record<string, string>
): Promise<ResponseWithData<void>> {
  return put('/update-my-keyboard-shortcuts', update);
}

export function putUpdateMyHonesty(
  update: Record<string, string>
): Promise<ResponseWithData<void>> {
  return put('/update-my-honesty', update);
}

export function putUpdateMyQuincyEmail(
  update: Record<string, string>
): Promise<ResponseWithData<void>> {
  return put('/update-my-quincy-email', update);
}

export function putUpdateMyPortfolio(
  update: Record<string, string>
): Promise<ResponseWithData<void>> {
  return put('/update-my-portfolio', update);
}

export function putUserAcceptsTerms(
  quincyEmails: boolean
): Promise<ResponseWithData<void>> {
  return put('/update-privacy-terms', { quincyEmails });
}

export function putUserUpdateEmail(
  email: string
): Promise<ResponseWithData<void>> {
  return put('/update-my-email', { email });
}

export function postCheckPassword(
  password: string
): Promise<ResponseWithData<void>> {
  return post('/check-password', { password });
}

export function putVerifyCert(
  certSlug: string
): Promise<ResponseWithData<void>> {
  return put('/certificate/verify', { certSlug });
}

/** DELETE **/
export function deleteUserToken(): Promise<ResponseWithData<void>> {
  return deleteRequest('/user/user-token', {});
}

// ##############################################
// #### AUTHENTICATION
// ##############################################

interface AuthLoginLocal {
  email: string;
  password: string;
}

export function postSignInLocal(
  formAuth: AuthLoginLocal
): Promise<ResponseWithData<void>> {
  return postAuth('/signin-local', { ...formAuth });
}

interface AuthSignUpLocal {
  name: string;
  phone: string;
  cpf: string;
  email: string;
  password: string;
}

export function postSignUpLocal(
  formAuth: AuthSignUpLocal
): Promise<ResponseWithData<void>> {
  return postAuth('/signup-local', { ...formAuth });
}

interface ForgotPassword {
  email: string;
}

export function postForgotPassword(
  formAuth: ForgotPassword
): Promise<ResponseWithData<void>> {
  return postAuth('/forgot-password', { ...formAuth });
}

interface FormRecoveryPassword {
  id: string;
  password: string;
  timeRequest: Date;
}

export function putRecoveryPassword(
  formRecovery: FormRecoveryPassword
): Promise<ResponseWithData<void>> {
  return putAuth('/recovery-password', { ...formRecovery });
}

export function putChangePassword(form: {
  currentPassword: string;
  newPassword: string;
}): Promise<ResponseWithData<void>> {
  return put('/change-password', { ...form });
}

export function putCreatePassword(form: {
  password: string;
}): Promise<ResponseWithData<void>> {
  return put('/create-password', { ...form });
}

export function postSendEmailVerification(
  formAuth: AuthLoginLocal
): Promise<ResponseWithData<void>> {
  return post('/email-verification', { ...formAuth });
}

interface VerificationEmail {
  id: string;
}

export function postConfirmVerificationEmail(
  idUser: VerificationEmail
): Promise<ResponseWithData<void>> {
  return post('/confirm-verification-email', { ...idUser });
}

// ##############################################
// #### E-COMMERCE
// ##############################################

export async function getAccessUserByEcommerce(cpf: string) {
  try {
    const response = await getEcommerce(`get-access-level?cpf=${cpf}`);
    return parseResultEcommerce(response);
  } catch (e) {
    console.log(e);
  }
}

interface MySubscription {
  name: string;
  cpf: string;
  phone: string;
  codeRegistration: string;
  access: string;
  subscription: boolean;
}
export function putRegisterSubscription(
  values: MySubscription
): Promise<ResponseWithData<void>> {
  return put('/register-subscription', { ...values });
}

interface PaymentUser {
  access: string;
  source: string;
}
export function putRegisterPaymentUser(
  values: PaymentUser
): Promise<ResponseWithData<void>> {
  return put('/register-payment-user', { ...values });
}

// ##############################################
// #### UTM
// ##############################################

export function putUtmData(values: unknown): Promise<ResponseWithData<void>> {
  return put('/register-utm-data', { ...values });
}

// ##############################################
// #### TOUR
// ##############################################

export async function putChangeTourStatus(
  tour: DataChangeTourStatusProps
): Promise<ResponseWithData<void>> {
  return await put('/change-tour-status', tour);
}

// ##############################################
// #### USER ANSWERS
// ##############################################

interface AnswerProp {
  id: string;
  answer: number | string;
}

export function postRegisterUserAnswer(
  payload: AnswerProp
): Promise<ResponseWithData<void>> {
  return post('/register-user-answer', payload);
}

// ##############################################
// #### INTEGRAÇÃO SGE: PROJETO NACIONAL
// ##############################################

export function getCourseCodeInfo(
  userUF: string
): Promise<
  ResponseWithData<{ result: { result: { data: CourseCodeDataProps[] } } }>
> {
  const responseWithData = get<{
    result: { result: { data: CourseCodeDataProps[] } };
  }>(`/api/courseCodes/get-codes?userUF=${userUF}`);

  return responseWithData;
}

export type Row = {
  ESTADO: string;
  UNIDADE: string;
  CODTURMA: string;
  DTMATRICULA: string;
  ALUUNO: string;
  CPF: string;
  IDTURMADISC: number;
  RA: string;
};

export type RegistrationResponseProps = {
  Row: Row[];
};

type GetCheckUserFirstRegistrationProps = {
  cpf: string;
  courseCode: string;
};

export async function getCheckUserFirstRegistration({
  cpf,
  courseCode
}: GetCheckUserFirstRegistrationProps): Promise<
  ResponseWithData<RegistrationResponseProps>
> {
  const responseWithData: Promise<ResponseWithData<RegistrationResponseProps>> =
    get(`/nacional?cpf=${formatCPF(cpf)}&courseCode=${courseCode}`);

  return responseWithData.then(response => ({
    response: response.response,
    data: response.data
  }));
}

export type PutUserCertificate = {
  ra: string;
  courseCode: string;
  amountOfExtraCertifications: number;
};

type UserCertificateProps = {
  Solicitado: {
    CodProva: string;
    Nota: number;
    RA: string;
    CodColigada: number;
    IdTurmaDisc: string;
    LMSouCTM: number;
  };
  Status: number;
  Mensagem: string;
};

export async function putUserCertificate({
  ra,
  courseCode,
  amountOfExtraCertifications
}: PutUserCertificate): Promise<ResponseWithData<UserCertificateProps>> {
  const responseWithData: Promise<ResponseWithData<UserCertificateProps>> = get(
    `/user-certificate?RA=${ra}&courseCode=${courseCode}&amountOfExtraCertifications=${amountOfExtraCertifications}`
  );

  return responseWithData.then(({ response, data }) => {
    return {
      response,
      data
    };
  });
}

export function submitLogRequest({
  userId,
  requestInfo
}: {
  userId: string;
  requestInfo: UserCertificateProps;
}): void {
  const requestPayload = {
    userId,
    requestInfo: requestInfo
  };
  void post('/api/logRequest/submit-log-request', { requestPayload });
}

// ##############################################
// #### PLATFORM MESSAGES
// ##############################################

export function postCreateMessage(
  payload: MessageProps
): Promise<ResponseWithData<void>> {
  const response = post('/api/messages/create-message', {
    messagePayload: payload
  });
  return response;
}

export interface MessageDataResponse<T> {
  status: number;
  message: string;
  result: T;
  error: boolean;
  success: boolean;
}
export async function getMessageListForUser(
  userId: string
): Promise<{ messageList: MessageProps[] }> {
  const responseWithData: Promise<
    ResponseWithData<{
      result: MessageDataResponse<{ messageList: MessageProps[] }>;
    }>
  > = get(`/api/messages/get-messages-for-user?userId=${userId}`);

  return await responseWithData.then(({ data }) => {
    return { messageList: data.result.result.messageList };
  });
}

export type FilterOptionsProps = {
  pageNumber?: number;
  pageSize?: number;
  search?: string;
  sort?: string;
  order?: 'ASC' | `DESC`;
};

type FilterProps = {
  filter?: FilterOptionsProps;
};

function buildQuery(filter: FilterOptionsProps): string {
  if (!filter) {
    return '';
  }

  const { pageNumber, pageSize, search, sort, order } = filter;

  const query = {
    ...(pageNumber && { pageNumber }),
    ...(pageSize && { pageSize }),
    ...(search && { search }),
    ...(sort && { sort }),
    ...(order && { order })
  };

  const queryValues = Object.entries(query);

  let urlQueryString = '';

  queryValues.forEach(([key, value], index) => {
    urlQueryString += `${key}=${value}${
      index < queryValues.length - 1 ? '&' : ''
    }`;
  });

  return urlQueryString;
}

interface MessageInfoProps {
  messageList: MessageProps[];
  messageListSize: number;
}
export async function getMessages({
  filter
}: FilterProps): Promise<MessageInfoProps> {
  const query: string = buildQuery(filter as FilterOptionsProps);

  const responseWithData: Promise<
    ResponseWithData<{ result: MessageDataResponse<MessageInfoProps> }>
  > = get(`/api/messages/get-messages?${query}`);

  return await responseWithData.then(({ data }) => {
    return {
      messageList: data.result.result.messageList,
      messageListSize: data.result.result.messageListSize ?? 0
    };
  });
}

export async function getMessageById(messageId: string): Promise<MessageProps> {
  const responseWithData: Promise<
    ResponseWithData<{ result: MessageDataResponse<{ message: MessageProps }> }>
  > = get(`/api/messages/get-message-by-id?messageId=${messageId}`);

  return await responseWithData.then(({ data }) => {
    return data.result.result.message;
  });
}

export async function postUpdateMessage(
  body: MessageProps
): Promise<ResponseWithData<void>> {
  const { id, ...updatedBody } = body;
  return put(`/api/messages/update-message?messageId=${id ?? ''}`, updatedBody);
}

async function fileUpload<T>(
  path: string,
  body: BodyInit
): Promise<ResponseWithData<T>> {
  const options: RequestInit = {
    ...defaultOptions,
    method: 'POST',
    headers: {
      'CSRF-Token': getCSRFToken()
    },
    body
  };

  const response = await fetch(`${base}${path}`, options);
  return combineDataWithResponse(response);
}

export function postUploadImage(
  image: File
): Promise<ResponseWithData<MessageDataResponse<{ metadata: ImageProps }>>> {
  const formData = new FormData();
  formData.append('image', image);

  return fileUpload('/upload-image', formData);
}

export function postUploadPublicImage(
  image: File
): Promise<ResponseWithData<MessageDataResponse<{ fileUrl: string }>>> {
  const formData = new FormData();
  formData.append('image', image);

  return fileUpload('/upload-public-image', formData);
}

export function deleteMessageById(
  id: string
): Promise<ResponseWithData<{ result: { count: number } }>> {
  return deleteRequest(`/api/messages/delete-by-id/${id}`, {});
}

export async function getImageLink(
  userId: string,
  action: string,
  filePath: string
): Promise<string> {
  const responseWithData = await get<{ result: { imageLink: string } }>(
    `/get-image-link/${userId}/generate-link?action=${action}&filePath=${filePath}`
  );
  return responseWithData.data.result.imageLink;
}

export async function getClassList(): Promise<string[]> {
  const responseWithData = await get<{ result: { result: string[] } }>(
    '/api/users/get-sources'
  );
  return responseWithData.data.result.result;
}

// ##############################################
// #### BLOCK INACTIVE USERS
// ##############################################

export async function checkUserInactiveStatus(
  userId: string
): Promise<boolean> {
  const responseWithData: Promise<
    ResponseWithData<{ result: { status: 'active' | 'blocked' } }>
  > = get(`/api/users/status/${userId}`);

  const { data } = await responseWithData;

  return data.result.status !== 'active';
}

export async function checkUserInactiveStatusByEmail(
  email: string
): Promise<string> {
  const responseWithData: Promise<
    ResponseWithData<{
      result: { result: { status: 'active' | 'blocked' } };
    }>
  > = get(`/api/users/status-by-email?email=${email}`);

  const { data } = await responseWithData;

  return data.result.result.status;
}

interface StandardReponse<T> {
  message: string;
  status: number;
  success: boolean;
  result: T;
}

export type UnlockUserAccountResponse = StandardReponse<{
  message: string;
  userId: string;
}>;

type HasPasswordResponse = {
  hasPassword: {
    message: string;
    status: number;
    success: boolean;
    result: { hasPassword: boolean };
  };
};

export async function unlockUserAccount(body: {
  cpf: string;
  password: string;
}): Promise<ResponseWithData<UnlockUserAccountResponse>> {
  const responseWithData: Promise<ResponseWithData<UnlockUserAccountResponse>> =
    post('/api/users/unlock-user-account', body);

  return await responseWithData;
}

export async function getUserHasPassword(
  cpf: string
): Promise<HasPasswordResponse> {
  try {
    const responseWithData: ResponseWithData<HasPasswordResponse> = await get(
      `/api/users/has-password?cpf=${cpf}`
    );

    return responseWithData.data;
  } catch (error) {
    console.error('Error:', error);
    return {
      hasPassword: {
        message: 'Erro ao verificar senha',
        status: 500,
        success: false,
        result: { hasPassword: false }
      }
    };
  }
}
