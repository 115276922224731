import { Modal } from '@devstart/react-bootstrap';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { ReactTyped } from 'react-typed';
import { hardGoTo } from '../../../redux/actions';
import { closeModal } from '../redux/actions';
import { isLimitedAccessModalOpenSelector } from '../redux/selectors';
import { courseSesiSenaiUrl } from '../../../../../config/env.json';
import { ButtonTypes } from '../../../components/ui/Button/button-types';
import Button from '../../../components/ui/Button';
import logoDEVstart from '../../../assets/images/logo-devstart.png';
import logoLAB365 from '../../../assets/images/logo-lab365.png';
import Close from '../../../assets/icons/close';
import { User } from '../../../redux/prop-types';
import { userSelector } from '../../../redux/selectors';
import { AccessLevel } from '../../../utils/enums/access-levels';

import './limited-access-modal.css';

interface LimitedAccessModalProps {
  readonly isOpen: boolean;
  readonly user: User;
  readonly close: () => void;
}

const mapStateToProps = createSelector(
  userSelector,
  isLimitedAccessModalOpenSelector,
  (user: User, isOpen: boolean) => ({
    user,
    isOpen
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      close: () => closeModal('limitedAccess'),
      navigate: hardGoTo
    },
    dispatch
  );

function LimitedAccessModal({
  user,
  isOpen,
  close
}: LimitedAccessModalProps): JSX.Element {
  const { t } = useTranslation();

  if (
    user.access === AccessLevel.LimitedNacional ||
    user.access === AccessLevel.CompleteNacional
  ) {
    return <></>;
  }

  return (
    <Modal
      animation={false}
      dialogClassName='limited-access-modal'
      keyboard={true}
      onHide={close}
      show={isOpen}
      enforceFocus={false}
    >
      <Close width={32} height={32} onClick={close} />

      <Modal.Body className='limited-access-body-modal'>
        <div className='content'>
          <div className='title'>
            <h4>
              {t('unlock-content.modal.unlock-full-content-1')}
              <br />
              {t('unlock-content.modal.unlock-full-content-2')}
            </h4>
            <h2>
              {t('unlock-content.modal.developer')} <br />
              <ReactTyped
                backSpeed={50}
                strings={[
                  t('unlock-content.modal.tech-1'),
                  t('unlock-content.modal.tech-2'),
                  t('unlock-content.modal.tech-3'),
                  t('unlock-content.modal.tech-4')
                ]}
                typeSpeed={50}
                loop
              />
            </h2>
          </div>

          <div className='details'>
            <p>{t('unlock-content.modal.details-p1')}</p>
            <p>{t('unlock-content.modal.details-p2')}</p>
            <p>{t('unlock-content.modal.details-p3')}</p>
          </div>

          <Button
            buttonType={ButtonTypes.Primary}
            style={{
              width: 440,
              height: 48,
              background: '#FFA1D0'
            }}
            onClick={() => {
              window.open(courseSesiSenaiUrl, '_blank');
            }}
          >
            {t('unlock-content.unlock-now')}
          </Button>
        </div>
      </Modal.Body>

      <div className='footer'>
        <img src={logoDEVstart} alt='logoDEVstart' width={117.57} />
        <img src={logoLAB365} alt='logoLAB365' width={126.54} />
      </div>
    </Modal>
  );
}

LimitedAccessModal.displayName = 'LimitedAccessModal';

export default connect(mapStateToProps, mapDispatchToProps)(LimitedAccessModal);
