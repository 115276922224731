import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import { findQuizUser } from '@utils/api/quiz';
import { navigate } from 'gatsby-link';
import { createFlashMessage } from '../components/Flash/redux';
import { FlashMessages } from '../components/Flash/redux/flash-messages';
import {
  postSignInLocal,
  postSignUpLocal,
  postConfirmVerificationEmail,
  putUtmData,
  postForgotPassword,
  putRecoveryPassword,
  putRegisterSubscription,
  checkUserInactiveStatusByEmail,
  unlockUserAccount,
  postDeleteAccount,
  putChangePassword,
  putCreatePassword
} from '../utils/ajax';
import { apiLocation } from '../../../config/env.json';
import {
  fetchUser,
  hardGoTo,
  openModalGoToUnlockPage,
  resetUserData,
  setLoadingChangePassword,
  startLoading,
  stopLoading
} from './actions';
import { isSignedInSelector } from './selectors';
import { actionTypes } from './action-types';
import { deleteAccountError } from './settings/actions';

function* submitSignUpLocal({ payload }) {
  try {
    yield put(startLoading('signUp'));

    const { success, result } = yield call(findQuizUser, {
      email: payload.email,
      cpf: payload.cpf
    });

    if (
      success &&
      result?.isDrParticipant &&
      result.status !== 'non-participant' &&
      result.status !== 'migrated'
    ) {
      yield put({
        type: actionTypes.updateQuizUserResultComplete,
        payload: result
      });

      yield put(stopLoading('signUp'));

      if (result.status === 'quiz-completed') {
        void navigate('/quiz/result');
      } else {
        void navigate('/quiz/steps');
      }

      return;
    }

    const signUpResult = yield call(postSignUpLocal, {
      email: payload.email,
      password: payload.password,
      cpf: payload.cpf,
      name: payload.name,
      phone: payload.phone
    });

    if (signUpResult.data.success) {
      window.dataLayer.push({
        event: 'gtm.formSubmit',
        'gtm.elementClasses': 'form-cadastro'
      });

      if (localStorage.getItem('devStart:utm') !== null) {
        yield put(updateAccessUtm(localStorage.getItem('devStart:utm')));
      }

      yield put(
        createFlashMessage({
          type: 'success',
          message: signUpResult.data.message
        })
      );
      yield delay(2000);
      yield put(hardGoTo('/learn'));
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message: signUpResult.data.message
        })
      );
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }

  yield put(stopLoading('signUp'));
}

function* submitSignInLocal({ payload }) {
  try {
    yield put(startLoading('signIn'));

    const userStatus = yield call(
      checkUserInactiveStatusByEmail,
      payload.email
    );

    if (userStatus === 'blocked') {
      const isSignedIn = yield select(isSignedInSelector);

      if (isSignedIn) {
        yield put(
          createFlashMessage({
            type: 'warning',
            message:
              'Usuário bloqueado por inatividade. Redirecionando para desbloqueio.'
          })
        );

        yield put(hardGoTo(`${apiLocation}/signout`));
      }

      yield put(stopLoading('signIn'));
      yield put(openModalGoToUnlockPage());
      return;
    }

    const { data } = yield call(postSignInLocal, payload);

    if (data.success) {
      delay(4000);
      yield put(hardGoTo('/learn'));
      yield put(createFlashMessage({ type: 'success', message: data.message }));
    } else if (data.error) {
      yield put(createFlashMessage({ type: 'danger', message: data.message }));
    }
  } catch (e) {
    console.log('Erro Authtentication: ', e);
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
  yield put(stopLoading('signIn'));
}

function* submitSubscriptionSaga({ payload }) {
  try {
    yield put(startLoading('subscription'));

    const { success, result } = yield call(findQuizUser, {
      email: payload.email,
      cpf: payload.cpf
    });

    if (
      success &&
      result?.isDrParticipant &&
      result.status !== 'non-participant' &&
      result.status !== 'migrated'
    ) {
      yield put({
        type: actionTypes.updateQuizUserResultComplete,
        payload: result
      });

      try {
        yield call(postDeleteAccount);
        yield put(resetUserData());

        if (result.status === 'quiz-completed') {
          yield call(navigate, '/quiz/result');
        } else {
          yield call(navigate, '/quiz/steps');
        }
      } catch (e) {
        yield put(deleteAccountError(e));
      }

      yield put(stopLoading('subscription'));

      return;
    }

    const dataComplementResult = yield call(putRegisterSubscription, payload);
    if (dataComplementResult.data.success) {
      window.dataLayer.push({
        event: 'gtm.formSubmit',
        'gtm.elementClasses': 'form-cadastro'
      });

      if (localStorage.getItem('devStart:utm') !== null) {
        yield put(updateAccessUtm(localStorage.getItem('devStart:utm')));
      }

      yield put(
        createFlashMessage({
          type: 'success',
          message: dataComplementResult.data.message
        })
      );
      yield put(hardGoTo('/learn'));
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message: dataComplementResult.data.message
        })
      );
    }
  } catch (e) {
    console.log(e);
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
  yield put(stopLoading('subscription'));
}

function* submitConfirmVerificationEmail({ payload }) {
  try {
    const { data } = yield call(postConfirmVerificationEmail, payload);
    if (data.success) {
      delay(2000);
      yield put(hardGoTo('/learn'));
      yield put(createFlashMessage({ type: 'success', message: data.message }));
    } else if (data.error) {
      yield put(createFlashMessage({ type: 'danger', message: data.message }));
    }
  } catch (e) {
    console.log('Erro Authtentication: ', e);
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
}

function* updateAccessUtm({ payload }) {
  try {
    const { data } = yield call(putUtmData, JSON.parse(payload));
    if (data.success) {
      localStorage.removeItem('devStart:utm');
    } else if (data.error) {
      yield put(createFlashMessage({ type: 'danger', message: data.message }));
    }
  } catch (e) {
    console.log('Erro Authtentication: ', e);
  }
}

function* submitForgotPassword({ payload }) {
  try {
    yield put(startLoading('sentEmailToRecovery'));

    const { data } = yield call(postForgotPassword, payload);
    if (data.success) {
      yield put(hardGoTo('/sent-email-to-recovery'));
    } else if (data.error) {
      yield put(createFlashMessage({ type: 'danger', message: data.message }));
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  } finally {
    yield put(stopLoading('sentEmailToRecovery'));
  }
}

function* submitRecoveryPassword({ payload }) {
  try {
    const { data } = yield call(putRecoveryPassword, payload);
    if (data.success) {
      yield put(createFlashMessage({ type: 'success', message: data.message }));
      yield put(hardGoTo('/learn'));
    } else if (data.error) {
      yield put(createFlashMessage({ type: 'error', message: data.message }));
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
}

function* submitChangePassword({ payload }) {
  try {
    yield put(setLoadingChangePassword(true));
    const { currentPassword, newPassword, reset } = payload;
    const { data } = yield call(putChangePassword, {
      currentPassword,
      newPassword
    });
    if (data?.result?.redirectLogout) {
      yield put(hardGoTo(`${apiLocation}/signout`));
    }
    if (data.success) {
      yield put(
        createFlashMessage({
          type: 'success',
          message: data.message,
          variables: { dismissible: true }
        })
      );
      reset();
    } else {
      if (data.message === 'A senha atual está incorreta. Tente novamente.') {
        yield put(
          createFlashMessage({
            type: 'warning',
            message: data.message,
            variables: { dismissible: true }
          })
        );
        return;
      }
      yield put(
        createFlashMessage({
          type: 'error',
          message: data.message,
          variables: { dismissible: true }
        })
      );
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  } finally {
    yield put(setLoadingChangePassword(false));
  }
}

function* submitCreatePassword({ payload }) {
  try {
    yield put(setLoadingChangePassword(true));
    const { data } = yield call(putCreatePassword, payload);
    if (data.success) {
      yield put(createFlashMessage({ type: 'success', message: data.message }));
      yield put(fetchUser());
    } else {
      yield put(createFlashMessage({ type: 'error', message: data.message }));
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  } finally {
    yield put(setLoadingChangePassword(false));
  }
}

function* submitCheckUserInactivityByEmail({ payload }) {
  try {
    const userStatus = yield call(checkUserInactiveStatusByEmail, payload);

    if (userStatus === 'blocked') {
      yield put(
        createFlashMessage({
          type: 'error',
          message: 'Usuário bloqueado por inatividade'
        })
      );
      yield put(hardGoTo('/unlock-account'));
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
}

function* submitUnlockUserAccount({ payload }) {
  try {
    const {
      data: { result }
    } = yield call(unlockUserAccount, payload);

    if (result.success) {
      if (payload.password === '') {
        yield put(
          createFlashMessage({
            type: 'success',
            message: result.result.message
          })
        );
        yield put(hardGoTo(`${apiLocation}/signin-google`));
      } else {
        const { data } = yield call(postSignInLocal, payload);

        if (data.success) {
          yield put(
            createFlashMessage({ type: 'success', message: data.message })
          );
          yield put(hardGoTo('/learn'));
        } else if (data.error) {
          yield put(
            createFlashMessage({ type: 'danger', message: data.message })
          );
        }
      }
    } else {
      yield put(
        createFlashMessage({ type: 'error', message: result.result.message })
      );
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
}

export function createAuthenticationSaga(types) {
  return [
    takeLatest(types.signInLocal, submitSignInLocal),
    takeLatest(types.signUpLocal, submitSignUpLocal),
    takeLatest(types.submitSubscription, submitSubscriptionSaga),
    takeLatest(types.verificationEmail, submitConfirmVerificationEmail),
    takeLatest(types.updateAccessUtm, updateAccessUtm),
    takeLatest(types.forgotPassword, submitForgotPassword),
    takeLatest(types.recoveryPassword, submitRecoveryPassword),
    takeLatest(types.changePassword, submitChangePassword),
    takeLatest(types.createPassword, submitCreatePassword),
    takeLatest(
      types.checkUserInactivityByEmail,
      submitCheckUserInactivityByEmail
    ),
    takeLatest(types.unlockUserAccount, submitUnlockUserAccount)
  ];
}
