import React from 'react';
import { navigate } from 'gatsby-link';
import { Dispatch, bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { ChallengeMeta, User } from '@redux/prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { removeFlashMessage } from '@components/Flash/redux';
import { userSelector } from '@redux/selectors';
import { AccessLevel } from '@utils/enums/access-levels';
import {
  challengeMetaSelector,
  isNotAllowedChallengeModalOpenSelector
} from '../../../templates/Challenges/redux/selectors';
import {
  nextChallenge,
  openModal
} from '../../../templates/Challenges/redux/actions';
import { ButtonTypes } from '../../ui/Button/button-types';
import Button from '../../ui/Button';

import './content-challenge-layout.css';
import '../challenge-toast.css';

type ContentChallengeLayoutProps = {
  readonly children: React.ReactNode;
  readonly disabledContinueButton: boolean;
  readonly challengeMeta: ChallengeMeta;
  readonly nextChallenge: () => void;
  readonly removeFlashMessage: typeof removeFlashMessage;
  readonly openModal: (modal: string) => void;
  readonly isNotAllowedChallenge: boolean;
  readonly user: User;
};

export const LAST_FREE_PERIOD_CHALLENGE_ID = '627167f7c7f9e60443ab2e01';

const mapStateToProps = createSelector(
  userSelector,
  challengeMetaSelector,
  isNotAllowedChallengeModalOpenSelector,
  (
    user: User,
    challengeMeta: ChallengeMeta,
    isNotAllowedChallenge: boolean
  ) => ({
    user,
    challengeMeta,
    isNotAllowedChallenge
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      nextChallenge,
      removeFlashMessage,
      openModal
    },
    dispatch
  );

function ContentChallengeLayout({
  children,
  disabledContinueButton,
  challengeMeta,
  nextChallenge,
  removeFlashMessage,
  openModal,
  isNotAllowedChallenge,
  user
}: ContentChallengeLayoutProps): JSX.Element {
  const handleExitClick = () => {
    const { superBlock } = challengeMeta;
    void navigate(`/learn/${superBlock}`);
    removeFlashMessage();
  };

  const isLastFreePeriodChallenge = (): boolean =>
    user.access === AccessLevel.LIMITED &&
    challengeMeta.id === LAST_FREE_PERIOD_CHALLENGE_ID;

  const handleContinueClick = () => {
    if (isLastFreePeriodChallenge()) {
      openModal('limitedAccess');
      return;
    }

    nextChallenge();
    removeFlashMessage();
  };

  return (
    <div className='content-challenge-layout-container'>
      {children}

      <div className='footer'>
        <div className='footer-button-options'>
          <Button
            buttonType={ButtonTypes.Tertiary}
            width='133px'
            onClick={handleExitClick}
          >
            SAIR
          </Button>
          <Button
            buttonType={ButtonTypes.Primary}
            width='133px'
            onClick={handleContinueClick}
            disabled={isNotAllowedChallenge || disabledContinueButton}
          >
            CONTINUAR
          </Button>
        </div>
      </div>
    </div>
  );
}

ContentChallengeLayout.displayName = 'ContentChallengeLayout';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ContentChallengeLayout));
