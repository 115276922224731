// eslint-disable-next-line import/no-cycle
import { Challenge, ChallengeMeta, User } from '../redux/prop-types';
// eslint-disable-next-line import/no-cycle
import { firstCheckpoint } from '../templates/Challenges/classic/show';
import { AccessLevel } from './enums/access-levels';

export function isUserAccessAllowed(
  challengeMeta: ChallengeMeta,
  completedChallengeIds: string[],
  challenge: Challenge,
  user: User,
  isUserRegistrationConfirmed?: boolean
): boolean {
  const { superOrder, phaseOrder, order, challengeOrder, id } = challenge;

  if (user.isBlockedByExpiration) {
    return false;
  }

  // se usuário bloqueado, só pode acessar até onde ele já acessou. Nada mais além disso.
  if (user.access === AccessLevel.BLOCKED) {
    const hasCompletedChallenge = completedChallengeIds.some(
      completed => completed === id
    );
    return hasCompletedChallenge;
  }

  // caso seja o primeiro exercício da trilha 0, pode liberar em todos os casos restante
  if (user.registrationQuiz == 1) {
    if (superOrder === 0 && order === 0 && challengeOrder === 0) {
      return true;
    }
  } else {
    // caso seja o primeiro exercício da primeira trilha padrão, pode liberar em todos os casos restante
    if (superOrder === 1 && order === 3 && challengeOrder === 0) {
      return true;
    }
  }

  // usuário limitados só podem acessar até o terceiro tema da trilha 1
  if (user.access === AccessLevel.LIMITED && (superOrder > 1 || order > 5)) {
    return false;
  }

  // Caso o estudo seja da trilha 1, não poderá acessar trilhas posteriores
  if (user.access === AccessLevel.TRILHA1 && superOrder > 1) {
    return false;
  }

  // caso estudo seja do projeto nacional, e ainda não tiver feito matrícula no SGE,
  // não podera acessar trilha e nem fase posterior ao do checkpoint 1
  if (
    user.access === AccessLevel.LimitedNacional &&
    !isUserRegistrationConfirmed &&
    (superOrder > firstCheckpoint.superOrder ||
      phaseOrder > firstCheckpoint.phaseOrder)
  ) {
    return false;
  }

  // verifica se o desafio anterior foi completado
  const isPreviousChallengeCompleted = completedChallengeIds.some(
    completed => completed === challengeMeta.prevChallengeId
  );
  return isPreviousChallengeCompleted;
}

export const userCompletedFirstCheckpointChallenge = (
  completedChallengeIds: string[]
): boolean => {
  const firstCheckpointId = firstCheckpoint.challengeId;
  const isCompletedChallenge = completedChallengeIds.some(
    completedChallengeId => completedChallengeId === firstCheckpointId
  );
  return isCompletedChallenge;
};

export function formatCPF(cpf: string): string {
  return cpf?.replace(/[.-]/g, '');
}

export function calculateGrade(
  realAnswers: number[],
  userAnswers: number[]
): number {
  return userAnswers.reduce(
    (acc, answer, index) => (answer === realAnswers[index] ? acc + 1 : acc),
    0
  );
}

export function continueProgressAvailable(user: User) {
  if (user.registrationQuiz === 1) {
    if (user.isFundamentosDaProgramacaoFrontEndCert === true) {
      return true;
    }
    return false;
  }

  if (user.access === AccessLevel.BLOCKED) {
    return false;
  }

  return true;
}

export function isNacionalUser(userAccess: string): boolean {
  return (
    userAccess === AccessLevel.LimitedNacional ||
    userAccess === AccessLevel.CompleteNacional
  );
}
