import { first } from 'lodash-es';
import React, { useState, useEffect, ReactElement } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CustomReflexSplitter from '@ui/CustomReflexSplitter';
import { sortChallengeFiles } from '../../../../../utils/sort-challengefiles';
import {
  ChallengeFile,
  ChallengeFiles,
  ChangeTourStatusProps,
  ResizeProps,
  User
} from '../../../redux/prop-types';
import { setShowPreviewPortal, setShowPreviewPane } from '../redux/actions';
import { changeTourStatus } from '../../../redux/actions';
import {
  createFlashMessage,
  removeFlashMessage
} from '../../../components/Flash/redux';
import {
  portalWindowSelector,
  showPreviewPortalSelector,
  showPreviewPaneSelector,
  isAdvancingToChallengeSelector
} from '../redux/selectors';
import PreviewPortal from '../components/preview-portal';
import BreadCrumb from '../components/bread-crumb';
import { userSelector } from '../../../redux/selectors';
import { exerciseTour } from '../../../components/Tour/steps';
import { FlashMessages } from '../../../components/Flash/redux/flash-messages';
import ActionRow from './action-row';

type Pane = { flex: number };

interface DesktopLayoutProps {
  block: string;
  superBlock: string;
  phase: string;
  title: string;
  slug: string;
  challengeFiles: ChallengeFiles;
  challengeType: number;
  editor: ReactElement | null;
  hasEditableBoundaries: boolean;
  hasNotes: boolean;
  hasPreview: boolean;
  instructions: ReactElement;
  testSuite: ReactElement;
  commandPanel: ReactElement;
  isAdvancing: boolean;
  isFirstStep: boolean;
  layoutState: {
    codePane: Pane;
    editorPane: Pane;
    instructionPane: Pane;
    testSuitePane: Pane;
    notesPane: Pane;
    previewPane: Pane;
    testsPane: Pane;
  };
  notes: ReactElement;
  preview: ReactElement;
  resizeProps: ResizeProps;
  testOutput: ReactElement;
  windowTitle: string;
  showPreviewPortal: boolean;
  showPreviewPane: boolean;
  setShowPreviewPortal: (arg: boolean) => void;
  setShowPreviewPane: (arg: boolean) => void;
  portalWindow: null | Window;
  user: User;
  isAllowedChallenge: boolean;
  changeTourStatus: ChangeTourStatusProps;
  createFlashMessage: typeof createFlashMessage;
  removeFlashMessage: typeof removeFlashMessage;
}

const reflexProps = {
  propagateDimensions: true
};

const mapDispatchToProps = {
  setShowPreviewPortal,
  setShowPreviewPane,
  changeTourStatus,
  createFlashMessage,
  removeFlashMessage
};

const mapStateToProps = createSelector(
  isAdvancingToChallengeSelector,
  showPreviewPortalSelector,
  showPreviewPaneSelector,
  portalWindowSelector,
  userSelector,
  (
    isAdvancing: boolean,
    showPreviewPortal: boolean,
    showPreviewPane: boolean,
    portalWindow: null | Window,
    user: User
  ) => ({
    isAdvancing,
    showPreviewPortal,
    showPreviewPane,
    portalWindow,
    user
  })
);

const DesktopLayout = (props: DesktopLayoutProps): JSX.Element => {
  const {
    showPreviewPane,
    showPreviewPortal,
    setShowPreviewPane,
    setShowPreviewPortal,
    portalWindow,
    user,
    changeTourStatus,
    createFlashMessage,
    removeFlashMessage
  } = props;

  const { t } = useTranslation();

  const [showNotes, setShowNotes] = useState(false);
  const [showConsole, setShowConsole] = useState(true);
  const [showInstructions, setShowInstructions] = useState(true);
  const [showTests, setShowTests] = useState(true);

  const togglePane = (pane: string): void => {
    switch (pane) {
      case 'showPreviewPane':
        if (!showPreviewPane && showPreviewPortal) setShowPreviewPortal(false);
        setShowPreviewPane(!showPreviewPane);
        portalWindow?.close();
        break;
      case 'showPreviewPortal':
        if (!showPreviewPortal && showPreviewPane) setShowPreviewPane(false);
        setShowPreviewPortal(!showPreviewPortal);
        if (showPreviewPortal) portalWindow?.close();
        break;
      case 'showConsole':
        setShowConsole(!showConsole);
        break;
      case 'showNotes':
        setShowNotes(!showNotes);
        break;
      case 'showInstructions':
        setShowInstructions(!showInstructions);
        break;
      case 'showTests':
        setShowTests(!showTests);
        break;
      default:
        setShowInstructions(true);
        setShowConsole(true);
        setShowTests(true);
        setShowPreviewPane(true);
        setShowPreviewPortal(false);
        setShowNotes(false);
    }
  };

  const getChallengeFile = () => {
    const { challengeFiles } = props;
    return first(sortChallengeFiles(challengeFiles) as ChallengeFile[]);
  };

  const {
    block,
    superBlock,
    phase,
    title,
    slug,
    resizeProps,
    instructions,
    testSuite,
    commandPanel,
    editor,
    testOutput,
    hasNotes,
    hasPreview,
    isAdvancing,
    isFirstStep,
    layoutState,
    notes,
    preview,
    hasEditableBoundaries,
    windowTitle,
    isAllowedChallenge
  } = props;

  // on mount
  useEffect(() => {
    if (isFirstStep) {
      setShowPreviewPortal(false);
      portalWindow?.close();
      setShowPreviewPane(true);
    } else if (!isAdvancing && !showPreviewPane && !showPreviewPortal) {
      togglePane('showPreviewPane');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notFinishedExerciseTour = !user?.finishedTours?.exerciseTour;

  const handleFinishTour = () => {
    driver.destroy();
    changeTourStatus({ finishedTours: { exerciseTour: true } });
    createFlashMessage({
      message: FlashMessages.TutorialModeHasEnded,
      type: 'success',
      variables: { dismissible: true }
    });
  };

  const driver = exerciseTour(handleFinishTour);

  useEffect(() => {
    if (notFinishedExerciseTour && isAllowedChallenge) {
      driver.drive();
    }

    return () => {
      driver.destroy();
      if (window.location.pathname !== slug) {
        removeFlashMessage();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const challengeFile = getChallengeFile();
  const projectBasedChallenge = hasEditableBoundaries;
  const displayPreviewPane = hasPreview && showPreviewPane;
  const displayPreviewPortal = hasPreview && showPreviewPortal;
  const displayNotes = projectBasedChallenge ? showNotes && hasNotes : false;
  const displayInstructionsAndTest = showInstructions && showTests;
  const displayEditorConsole = showConsole;
  const {
    codePane,
    editorPane,
    instructionPane,
    notesPane,
    previewPane,
    testsPane
  } = layoutState;

  const breadCrumbItems = [
    {
      key: 'superblock-title',
      value: t(`intro:${superBlock}.title`),
      redirectsTo: `/learn/${superBlock}`
    },
    {
      key: 'superblock-phase',
      value: t(`intro:${superBlock}.phases.${phase}.title`),
      redirectsTo: `/learn/${superBlock}/#${block}`
    },
    {
      key: 'superblock-theme',
      value: t(`intro:${superBlock}.phases.${phase}.blocks.${block}.title`),
      redirectsTo: `/learn/${superBlock}/#${block}`
    },
    {
      key: 'superblock-challenge',
      value: title,
      redirectsTo: ''
    }
  ];

  return (
    <>
      <div className='tools-container'>
        <BreadCrumb breadCrumbItems={breadCrumbItems} />
      </div>

      <div className='desktop-layout'>
        <ActionRow
          hasNotes={hasNotes}
          showConsole={showConsole}
          showNotes={showNotes}
          showInstructions={showInstructions}
          showPreviewPane={showPreviewPane}
          showPreviewPortal={showPreviewPortal}
          togglePane={togglePane}
          showTests={showTests}
          hasPreview={hasPreview}
        />

        <ReflexContainer orientation='vertical'>
          {(showInstructions || showTests) && (
            <ReflexElement
              flex={instructionPane.flex}
              {...resizeProps}
              {...reflexProps}
            >
              <ReflexContainer orientation='horizontal'>
                {showInstructions && (
                  <ReflexElement
                    flex={instructionPane.flex}
                    {...reflexProps}
                    {...resizeProps}
                  >
                    {instructions}
                  </ReflexElement>
                )}

                {displayInstructionsAndTest && (
                  <CustomReflexSplitter
                    propagate={true}
                    className='custom-splitter separated-splitter step-for-tour-splitter-1'
                    {...resizeProps}
                  />
                )}

                {showTests && (
                  <ReflexElement flex={instructionPane.flex} {...resizeProps}>
                    {testSuite}
                  </ReflexElement>
                )}
              </ReflexContainer>
            </ReflexElement>
          )}

          {(showInstructions || showTests) && (
            <CustomReflexSplitter
              propagate={true}
              className='custom-splitter separated-splitter step-for-tour-splitter-2'
            />
          )}

          <ReflexElement
            flex={editorPane.flex}
            {...resizeProps}
            {...reflexProps}
          >
            {challengeFile && (
              <ReflexContainer
                key={challengeFile.fileKey}
                orientation='horizontal'
              >
                <ReflexElement
                  flex={codePane.flex}
                  {...reflexProps}
                  {...resizeProps}
                  style={{
                    border: '1px solid #d6d6d6',
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px'
                  }}
                >
                  <div id='div-reflex-editor'>{editor}</div>
                </ReflexElement>
                {displayEditorConsole && (
                  <CustomReflexSplitter
                    propagate={true}
                    className='custom-splitter separated-splitter step-for-tour-splitter-3'
                    {...resizeProps}
                  />
                )}
                {displayEditorConsole && (
                  <ReflexElement
                    flex={testsPane.flex}
                    {...resizeProps}
                    style={{
                      border: '1px solid #d6d6d6'
                    }}
                  >
                    {testOutput}
                  </ReflexElement>
                )}

                <div
                  style={{
                    border: '1px solid #d6d6d6',
                    borderTop: 'none',
                    padding: '8px',
                    borderBottomLeftRadius: '8px',
                    borderBottomRightRadius: '8px'
                  }}
                >
                  {commandPanel}
                </div>
              </ReflexContainer>
            )}
          </ReflexElement>

          {displayNotes && <ReflexSplitter propagate={true} {...resizeProps} />}
          {displayNotes && (
            <ReflexElement flex={notesPane.flex} {...resizeProps}>
              {notes}
            </ReflexElement>
          )}

          {displayPreviewPane && (
            <CustomReflexSplitter
              propagate={true}
              className='custom-splitter separated-splitter'
              {...resizeProps}
            />
          )}

          {displayPreviewPane && (
            <ReflexElement flex={previewPane.flex} {...resizeProps}>
              <ReflexContainer orientation='horizontal'>
                {displayPreviewPane && <ReflexElement>{preview}</ReflexElement>}
              </ReflexContainer>
            </ReflexElement>
          )}
        </ReflexContainer>

        {displayPreviewPortal && (
          <PreviewPortal windowTitle={windowTitle}>{preview}</PreviewPortal>
        )}
      </div>
    </>
  );
};

DesktopLayout.displayName = 'DesktopLayout';

export default connect(mapStateToProps, mapDispatchToProps)(DesktopLayout);
