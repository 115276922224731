import { SuperBlocks } from '../../../config/certification-settings';
import { ns as MainApp } from './action-types';

export const savedChallengesSelector = state =>
  userSelector(state).savedChallenges || [];
export const completedChallengesSelector = state =>
  userSelector(state).completedChallenges || [];
export const partiallyCompletedChallengesSelector = state =>
  userSelector(state).partiallyCompletedChallenges || [];
export const completionCountSelector = state => state[MainApp].completionCount;
export const currentChallengeIdSelector = state =>
  state[MainApp].currentChallengeId;

export const emailSelector = state => userSelector(state).email;

export const codeRegistrationSelector = state =>
  state[MainApp].codeRegistration;

export const isDonatingSelector = state => userSelector(state).isDonating;
export const isOnlineSelector = state => state[MainApp].isOnline;
export const isServerOnlineSelector = state => state[MainApp].isServerOnline;
export const isSignedInSelector = state => !!state[MainApp].appUsername;
export const isSignoutModalOpenSelector = state =>
  state[MainApp].showSignoutModal;
export const isModalGoToUnlockPageOpenSelector = state => {
  return state[MainApp].showModalGoToUnlockPage;
};
export const recentlyClaimedBlockSelector = state =>
  state[MainApp].recentlyClaimedBlock;
export const signInLoadingSelector = state =>
  userFetchStateSelector(state).pending;
export const showCertSelector = state => state[MainApp].showCert;
export const showCertFetchStateSelector = state =>
  state[MainApp].showCertFetchState;

export const userTokenSelector = state => {
  return userSelector(state).userToken;
};

export const showCodeAllySelector = state => {
  return state[MainApp].showCodeAlly;
};

export const userByNameSelector = username => state => {
  const { user } = state[MainApp];
  // return initial state empty user empty object instead of empty
  // object litteral to prevent components from re-rendering unnecessarily
  // TODO: confirm if "initialState" can be moved here or action-types.js
  return user[username] ?? {};
};

export const currentCertsSelector = state =>
  certificatesByNameSelector(state[MainApp]?.appUsername)(state)?.currentCerts;

export const certificatesByNameSelector = username => state => {
  const {
    isFundamentosDaProgramacaoFrontEndCert,
    isLogicaDeProgramacaoCert,
    isHtmlCssJavascriptCert,
    isReactCert
  } = userByNameSelector(username)(state);
  return {
    currentCerts: [
      {
        show: isFundamentosDaProgramacaoFrontEndCert,
        title: 'Frontend Programming Fundamentals',
        certSlug: SuperBlocks.LogicaDeProgramacao
      },
      {
        show: isLogicaDeProgramacaoCert,
        title: 'Logic Programming Certification',
        certSlug: SuperBlocks.LogicaDeProgramacao
      },
      {
        show: isHtmlCssJavascriptCert,
        title: 'Html, CSS and Javascript Certification',
        certSlug: SuperBlocks.HtmlCssJavascript
      },
      {
        show: isReactCert,
        title: 'React library Certification',
        certSlug: SuperBlocks.React
      }
    ],
    legacyCerts: []
  };
};

export const userFetchStateSelector = state => state[MainApp].userFetchState;
export const allChallengesInfoSelector = state =>
  state[MainApp].allChallengesInfo;
export const userProfileFetchStateSelector = state =>
  state[MainApp].userProfileFetchState;
export const usernameSelector = state => state[MainApp].appUsername;
export const userSelector = state => {
  const username = usernameSelector(state);

  return state[MainApp].user[username] || {};
};

export const courseCodeInfoSelector = state => {
  return state[MainApp].courseCodeInfo;
};
export const userRegistrationStatusSelector = state => {
  return state[MainApp].userRegistrationStatus;
};

export const messageListSelector = state => {
  return state[MainApp].messageList;
};

export const selectSignInLoading = state => {
  return state[MainApp].loading.signIn;
};

export const selectSignUpLoading = state => {
  return state[MainApp].loading.signUp;
};

export const selectSubscriptionLoading = state => {
  return state[MainApp].loading.subscription;
};

export const selectSentEmailToRecoveryLoading = state => {
  return state[MainApp].loading.sentEmailToRecovery;
};

export const automaticEmailsSelector = state => {
  return state[MainApp].automaticEmails;
};

export const isLoadingAutomaticEmailsSelector = state => {
  return state[MainApp].automaticEmails.loading;
};

export const manualEmailSelector = state => {
  return state[MainApp].manualEmails;
};

export const isLoadingManualEmailsSelector = state => {
  return state[MainApp].manualEmails.loading;
};

export const recurringEmailSelector = state => {
  return state[MainApp].recurringEmail;
};

export const isLoadingRecurringEmailsSelector = state => {
  return state[MainApp].recurringEmail.loading;
};

export const showEmailDrawerSelector = state => {
  return state[MainApp].showEmailDrawer;
};

export const quizUserSelector = state => {
  return state[MainApp].quizUser.data;
};

export const isLoadingRegisterQuizUserSelector = state => {
  return state[MainApp].quizUser.loading;
};

export const isLoadingChangePasswordSelector = state => {
  return state[MainApp].loading.changePassword;
};
