import { Router } from '@reach/router';
import React from 'react';

import FourOhFour from '../components/FourOhFour';

function FourOhFourPage(): JSX.Element {
  return (
    <Router>
      <FourOhFour default={true} />
    </Router>
  );
}

FourOhFourPage.displayName = 'FourOhFourPage';

export default FourOhFourPage;
