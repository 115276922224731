import { omit } from 'lodash-es';
import { ofType } from 'redux-observable';
import { empty, of } from 'rxjs';
import { catchError, concat, filter, retry, switchMap } from 'rxjs/operators';
import { createFlashMessage } from '@components/Flash/redux';
import { FlashMessages } from '@components/Flash/redux/flash-messages';
import { challengeTypes, submitTypes } from '../../../../utils/challenge-types';
import {
  submitComplete,
  updateComplete,
  updateFailed
} from '../../../redux/actions';
import { isSignedInSelector, userSelector } from '../../../redux/selectors';
import { mapFilesToChallengeFiles } from '../../../utils/ajax';
import { standardizeRequestBody } from '../../../utils/challenge-request-helpers';
import postUpdate$ from '../utils/post-update';
import { calculateGrade } from '../../../utils/general-functions';
import { actionTypes } from './action-types';
import {
  setCompletedChallengesLoading,
  updateSolutionFormValues
} from './actions';
import {
  challengeFilesSelector,
  challengeMetaSelector,
  challengeTestsSelector,
  projectFormValuesSelector
} from './selectors';

function postChallenge(update, username, challengeFiles = []) {
  return of(setCompletedChallengesLoading(true)).pipe(
    concat(
      postUpdate$(update).pipe(
        retry(3),
        switchMap(({ data }) => {
          console.log(data);

          if (data.type === 'error') {
            return of(
              updateFailed(update),
              setCompletedChallengesLoading(false),
              createFlashMessage({
                message:
                  'Erro ao registrar resposta. Tente novamente mais tarde ou contate a equipe de suporte.',
                type: 'error',
                variables: { dismissible: true }
              })
            );
          }

          const { savedChallenges, points } = data;
          const payloadWithClientProperties = {
            ...omit(update.payload, ['files'])
          };
          if (update.payload.files) {
            payloadWithClientProperties.challengeFiles =
              update.payload.files.map(({ key, ...rest }) => ({
                ...rest,
                fileKey: key
              }));
          }
          return of(
            submitComplete({
              submittedChallenge: {
                username,
                points,
                ...payloadWithClientProperties
              },
              savedChallenges: mapFilesToChallengeFiles(savedChallenges)
            }),
            updateComplete(),
            setCompletedChallengesLoading(false),
            createFlashMessage({
              message: FlashMessages.CongratsChallengeComplete,
              type: 'success',
              variables: { dismissible: true },
              challengeFiles
            })
          );
        }),
        catchError(() =>
          of(
            updateFailed(update),
            setCompletedChallengesLoading(false),
            createFlashMessage({
              message:
                'Erro ao registrar resposta. Tente novamente mais tarde ou contate a equipe de suporte.',
              type: 'error',
              variables: { dismissible: true }
            })
          )
        ) // Atualiza a flag mesmo em caso de erro
      )
    )
  );
}

function submitModern(type, state) {
  const challengeType = state.challenge.challengeMeta.challengeType;
  const tests = challengeTestsSelector(state);
  if (
    challengeType === 11 ||
    challengeType === 15 ||
    (tests.length > 0 && tests.every(test => test.pass && !test.err))
  ) {
    if (type === actionTypes.checkChallenge) {
      return of({ type: 'this was a check challenge' });
    }

    if (type === actionTypes.submitChallenge) {
      const { id, block } = challengeMetaSelector(state);
      const challengeFiles = challengeFilesSelector(state);
      const { username } = userSelector(state);

      let body;

      if (
        block === 'javascript-algorithms-and-data-structures-projects' ||
        challengeType === challengeTypes.multifileCertProject
      ) {
        body = standardizeRequestBody({ id, challengeType, challengeFiles });
      } else {
        const { files } = standardizeRequestBody({
          id,
          challengeType,
          challengeFiles
        });

        body = files ? { id, files, challengeType } : { id, challengeType };
      }

      const update = {
        endpoint: '/modern-challenge-completed',
        payload: body
      };

      return postChallenge(update, username, challengeFiles);
    }
  }

  return empty();
}

function submitVideo(type, state) {
  const challengeType = state.challenge.challengeMeta.challengeType;
  if (type === actionTypes.checkChallenge) {
    return of({ type: 'this was a check challenge' });
  }

  if (type === actionTypes.submitChallenge) {
    const { id, block } = challengeMetaSelector(state);
    const challengeFiles = challengeFilesSelector(state);
    const { username } = userSelector(state);

    let body;
    if (
      block === 'javascript-algorithms-and-data-structures-projects' ||
      challengeType === challengeTypes.multifileCertProject
    ) {
      body = standardizeRequestBody({ id, challengeType, challengeFiles });
    } else {
      body = {
        id,
        challengeType
      };
    }

    const update = {
      endpoint: '/modern-challenge-completed',
      payload: body
    };
    return postChallenge(update, username);
  }
  return empty();
}

function submitProject(type, state) {
  if (type === actionTypes.checkChallenge) {
    return empty();
  }

  const { solution, githubLink } = projectFormValuesSelector(state);
  const { id, challengeType } = challengeMetaSelector(state);
  const { username } = userSelector(state);
  const challengeInfo = { id, challengeType, solution };
  if (challengeType === challengeTypes.backEndProject) {
    challengeInfo.githubLink = githubLink;
  }

  const update = {
    endpoint: '/project-completed',
    payload: challengeInfo
  };
  return postChallenge(update, username).pipe(
    concat(of(updateSolutionFormValues({})))
  );
}

function submitBackendChallenge(type, state) {
  const tests = challengeTestsSelector(state);
  if (tests.length > 0 && tests.every(test => test.pass && !test.err)) {
    if (type === actionTypes.submitChallenge) {
      const { id } = challengeMetaSelector(state);
      const { username } = userSelector(state);
      const {
        solution: { value: solution }
      } = projectFormValuesSelector(state);
      const challengeInfo = { id, solution };

      const update = {
        endpoint: '/backend-challenge-completed',
        payload: challengeInfo
      };
      return postChallenge(update, username);
    }
  }
  return empty();
}

function submitQuiz(type, state) {
  const challengeType = state.challenge.challengeMeta.challengeType;
  const answers = state.challenge.quizAnswers;
  if (type === actionTypes.submitChallenge) {
    const { id } = challengeMetaSelector(state);
    const { username } = userSelector(state);
    let body;
    const score = calculateGrade(answers.realAnswers, answers.userAnswers);

    body = {
      id,
      challengeType,
      grade: score,
      userAnswers: answers.userAnswers
    };

    const update = {
      endpoint: '/quiz-challenge-completed',
      payload: body
    };

    return postChallenge(update, username);
  }

  return empty();
}

const submitters = {
  tests: submitModern, // Videos challenges with quiz
  backend: submitBackendChallenge, // NOT IN USE
  noTests: submitVideo, // Videos and text challenges
  'project.frontEnd': submitProject, // Submit github project
  'project.backEnd': submitProject, // NOT IN USE
  quiz: submitQuiz // Quiz MundoTech
};

export default function completionEpic(action$, state$) {
  return action$.pipe(
    ofType(actionTypes.submitChallenge),
    switchMap(({ type }) => {
      const state = state$.value;

      const { challengeType } = challengeMetaSelector(state);

      let submitter = () => of({ type: 'no-user-signed-in' });
      if (
        !(challengeType in submitTypes) ||
        !(submitTypes[challengeType] in submitters)
      ) {
        throw new Error(
          'Unable to find the correct submit function for challengeType ' +
            challengeType
        );
      }

      if (isSignedInSelector(state)) {
        submitter = submitters[submitTypes[challengeType]];
      }
      return submitter(type, state).pipe(filter(Boolean));
    })
  );
}
